@font-face {
    font-family: 'Bebas';
    src: url('./assets/fonts/Bebas-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Pokemon';
    src: url('./assets/fonts/Pokemon\ Classic.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Cafe';
    src: url('./assets/fonts/Louis\ George\ Cafe.ttf') format('truetype');
  }
 

  .smallGutter{
    margin-top: 24px;
    margin-bottom: 24px;
  }

