$white: #ffffff;
$black: #000000;

$whiteSecondary: #4576CF;
$blackSecondary: #6D1485;

// define the background colors for light and dark modes
$light-bg: $white;
$dark-bg: $black;

// define the text colors for light and dark modes
$light-text: $black;
$dark-text: $white;

// define the common styles for light and dark modes
.common-styles {
  transition: all 0.3s linear 0s;
  p{
    font-size: 1.2em;
  }

  // add any other shared styles you want to apply in both modes
}

// define the styles for light mode
.lightMode {
  background-color: $light-bg;
  color: $light-text;
  @extend .common-styles;


  .divider-shape-fill {
    fill:$whiteSecondary;
}
  body, p{
    font-family: 'Cafe', sans-serif !important;
  }
  h1, h2, h3, h4, footer{
    font-family: 'Bebas', sans-serif !important;
  }
  p, a{
    color: $light-text;
  }
  a:hover{
    color: $light-bg;
  }
  .card{
    background-color: $light-bg;
    color: $light-text;
    a{
      color: $light-text;
    }
    a:hover{
      color: $light-bg;
    }
    .card-body{
      background-color: $light-bg;
      color: $light-text;
      a{
        color: $light-text;
      }
      a:hover{
        color: $light-bg;
      }
      .card-text{
        color: $light-text;
      }
      .card-title{
        color: $light-text;
      }
    }
  }
}

// define the styles for dark mode
.darkMode {
  background-color: $dark-bg;
  color: $dark-text;
  @extend .common-styles;
  .divider-shape-fill {
    fill: $blackSecondary;
}
  .sidenav{
    background-color: $dark-bg;
    color: $dark-text;
  }
  body, p{
    font-family: 'Cafe', sans-serif !important;
  }
  h1, h2, h3, h4, footer{
    font-family: 'Bebas', sans-serif !important;
  }
  p, a{
    color: $dark-text;
  }
  a:hover{
    color: $dark-bg;
  }
  .card{
    background-color: $dark-bg;
    color: $dark-text;
    a{
      color: $dark-text;
    }
    a:hover{
      color: $dark-bg;
    }
    .card-body{
      background-color: $dark-bg;
      color: $dark-text;
      a{
        color: $dark-text;
      }
      a:hover{
        color: $dark-bg;
      }
      .card-text{
        color: $dark-text;
      }
      .card-title{
        color: $dark-text;
      }
    }
  }
}
